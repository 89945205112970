import React from "react"
import styled from "styled-components"

const Container = styled.div`
  flex: ${props => props.flex};
  padding: 0.5vh;
`
const P = styled.p``

const Text = ({ text, flex }) => (
  <Container flex={flex}>
    <P dangerouslySetInnerHTML={{ __html: text }} />
  </Container>
)

export default Text
