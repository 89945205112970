import React from "react"
import { graphql } from "gatsby"
import Wip from "../components/wip"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO
      title=" ⠀ "
      keywords={[
        `Julia`,
        `Rosenstock`,
        `Portfolio`,
        `Berlin`,
        `Fotografie`,
        `Photography`,
        `Artdirection`,
        `Iloveyou`,
        `Magazine`,
        `UDK`,
        `Gaabs`,
      ]}
    />
    <Wip project={data.markdownRemark.frontmatter} />
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        published
        slider {
          images {
            src {
              childImageSharp {
                fluid(maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        wip {
          row {
            type
            text
            metadata
            src {
              childImageSharp {
                fluid(maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
            flex
          }
        }
      }
    }
  }
`
