import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

const Img = styled(GatsbyImage)`
  & img[style] {
    object-fit: contain !important;
    max-height: fill-available !important;
    padding: 2vh;
    @media (max-width: 960px) {
      padding: 0;
    }
  }
`

const Container = styled.div`
  flex: ${props => props.flex};
`

const Image = ({ src, alt, flex }) => (
  <Container flex={flex}>
    <Img alt={alt} fluid={src.childImageSharp.fluid} />
  </Container>
)

export default Image
