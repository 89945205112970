import React from "react"
import styled from "styled-components"

const Container = styled.div`
  flex: ${props => props.flex};
  padding:0.5vh;
`

const P = styled.p` 
text-transform: uppercase;
letter-spacing: 0.04vh;
`

const Metadata = ({ metadata, flex }) => (
  <Container flex={flex}>
    {metadata.split("\n").map((line, i) => (
      <P key={i}>{line}</P>
    ))}
  </Container>
)

export default Metadata
