import React from "react"
import styled from "styled-components"

import Text from "./text"
import Metadata from "./metadata"
import Image from "./image"
import Spacer from "./spacer"
import Btn from "./button"

export const Container = styled.div`
  position: fixed;
  /* align-self: center; */
  height: 100%;
  width: 100%;
  top: 0;
  background: white;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 960px) {
    overflow-y: scroll;
  }
`

const Rows = styled.div`
  top: ${props => props.theme.headerHeight};
  display: flex;
  flex-direction: column;
  padding-top: 3.5vh;
`
const MenuBar = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.headerHeight};
  @media (max-width: 960px) {
    height: 5vh;
  }
  background: white;
  overflow: hidden;
`

const P = styled.p`
  letter-spacing: 0.05em;
  line-height: 1em;
`

export const Row = styled.div`
  display: flex;
  min-height: 0;
  flex: 1;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`

const Wip = ({ project }) => {
  return (
    <Container>
      <MenuBar>
        <P>»{project.title}«</P>
        <Btn as="div" onClick={() => global.history.back()}>CLOSE</Btn>
      </MenuBar>
      <Rows>
        {project.wip.map(({ row }, i) => (
          <Row key={i}>
            {row.slice(1).map(({ type, ...wipData }, i) => {
              switch (type) {
                case "text":
                  return <Text key={i} {...wipData} />
                case "metadata":
                  return <Metadata key={i} {...wipData} />
                case "image":
                  return <Image key={i} {...wipData} />
                case "spacer":
                  return <Spacer key={i} {...wipData} />
                default:
                  return null
              }
            })}
          </Row>
        ))}
      </Rows>
    </Container>
  )
}

export default Wip
